import { useCallback } from 'react';

import { BookDefinition } from '@/components/common/Book';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { BookViewItem, SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import { NewReleaseItem, SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

import { GenreHome2InfoPreset, GenreHomeBookItemRenderer } from '../../common/GenreHomeBook';
import { ListLayoutSection } from '../../common/ListLayoutSection';
import { SectionLayout } from '../../common/SectionLayout';

export interface NewReleaseProps {
  section: SectionByLayout<SectionLayoutType.NewRelease>;
}

export const NewRelease = ({ section }: NewReleaseProps): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();
  const renderItem = useCallback(
    ({ item, index }: { item: BookViewItem<NewReleaseItem>; index: number }) => (
      <GenreHomeBookItemRenderer item={item} index={index} />
    ),
    [],
  );

  return (
    <BookDefinition presets={GenreHome2InfoPreset} trackingData={sectionTrackingData.bookDefinition}>
      <SectionLayout title={section.title} link={section.more_details?.path} paddingBottom={false}>
        <ListLayoutSection items={section.items} renderItem={renderItem} />
      </SectionLayout>
    </BookDefinition>
  );
};
